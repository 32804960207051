/* Global styles */
body {
  font-family: Arial, sans-serif;
  margin: 0;
  padding: 0;
  background: linear-gradient(to bottom, turquoise, coral);
  color: white;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  min-height: 100vh;
  overflow: hidden;
}

/* Header styles */
header {
  background-color: coral;
  width: 100%;
  position: fixed;
  top: 0;
  left: 0;
  padding-top: 1rem;
}

/* Navigation styles */
nav ul {
  list-style: none;
  padding: 0;
  margin: 0;
}

nav ul li {
  display: inline;
  margin-right: 20px;
}

nav ul li:first-child {
  margin-right: 40px;
}

nav ul li a {
  text-decoration: none;
  color: white;
  font-size: 24px;
  font-weight: bold;
  transition: transform 0.3s ease-in-out;
  padding: 1rem;
}

nav ul li a:hover {
  color: turquoise;
  transform: scale(1.05);
}

/* Main content styles */
.main {
  text-align: center;
  padding: 30px;
  flex: 1;
  display: flex;
  flex-direction: column;
  align-items: center;
  padding-top: 20%;
  width: 100%;
}

/* Center the content vertically */
.App {
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  min-height: 100vh;
  overflow: hidden;
}

/* Translation container styles */
.translation-container {
  background-color: rgba(255, 255, 255, 0.2);
  border-radius: 10px;
  padding: 20px;
  max-width: 450px;
  width: 90%;
  align-items: center;
}

/* Input container styles */
.input-container {
  display: flex;
  flex-direction: column;
  align-items: center;
}

/* Input field styles */
input {
  margin: 10px 0;
  padding: 10px;
  border: none;
  border-radius: 5px;
  width: 100%;
}

/* Button styles */
button {
  background-color: coral;
  color: white;
  border: none;
  padding: 10px 20px;
  border-radius: 5px;
  cursor: pointer;
  transition: background-color 0.3s;
  width: auto; /* Adjusted button width */
  max-width: 100px; /* Set a maximum width for the button */
}

button:hover {
  background-color: turquoise;
}

/* Translated text styles */
.translated-text {
  margin-top: 20px;
  text-align: center; /* Center the text horizontally */
  width: 100%;
}

/* Copy container styles */
.copy-container {
  display: flex;
  align-items: center;
  justify-content: center; /* Center the copy button horizontally */
  margin-top: 10px; /* Add margin-top to create a gap */
}

/* Copy button styles */
.copy-button {
  background: none;
  border: none;
  cursor: pointer;
  padding-left: .5rem; /* Add a bit of left padding for spacing */
}

/* Copy icon styles */
.copy-icon {
  font-size: 18px; /* Adjust the size of the clipboard icon */
}

/* Update the mic-button class to make it transparent */
.mic-button {
  background: none; /* Remove background color */
  border: none;
  cursor: pointer;
}

/* Update the microphone icon to have a white color */
.mic-button i {
  color: white;
  font-size: 16px; /* Adjust the size as needed */
}

.copy-button:hover,
.mic-button:hover {
  background-color: transparent;
}
